import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Callcenter } from "./callcenter.type";
const HASH_URL = process.env.REACT_APP_HASH_URL
/**
 * Initial state object
 */
const initialState: Callcenter = {};

const enquireTransaction = createAsyncThunk(
  "callcenter/enquireTransactionCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/via-mw/inquire`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(HASH_URL!.concat(pathname), body, { headers })
      );
      console.log("Success response from transaction enquiry :", response.data);

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const refundTransaction = createAsyncThunk(
  "callcenter/enquireTransactionCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/via-mw/refund`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(HASH_URL!.concat(pathname), body, { headers })
      );
      console.log("Success response from refund :", response.data);

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);


const callcenterSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "callcenter",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setCallcenter: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
 
});

/**
 * Reducers are exported so they could be added to store
 */
export const callcenterReducer = callcenterSlice.reducer;

export const callcenterActions = { ...callcenterSlice.actions, enquireTransaction, refundTransaction };
