import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {useHistory} from 'react-router-dom'
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@mui/material/styles/styled";
import Toolbar from "@mui/material/Toolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HashirLogo from "&assets/images/final-logo-black.png";
import { Divider } from "@mui/material";

import {menu} from '&styled/drawer/menu';
import {hasChildren} from '&utils/drawerMenu';
import { verifyPermission } from "&utils/verifyPermission";

const drawerWidth = 260;

interface Props {
  window?: () => Window;
  children?: any;
  setType?: Function;
  logOut?: Function;
  resetReport?: Function;
  getMerchantInRedis?: any;
  setBillingType?: Function;
  user?: any;
}

const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#F6F2FF",
    "&, & .MuiListItemIcon-root": {
      color: "#6631F7",
      fill: "#6631F7",
    },
  },
});

export default function WaleeDrawer(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const {
    window,
    children,
    logOut,
    getMerchantInRedis,
    user,
  } = props;
  const {  role, userTypeId } = user;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [
    settingAnchorEl,
    setSettingAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const [
    profileAnchorEl,
    setProfileAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const settingOpen = Boolean(settingAnchorEl);
  const profileOpen = Boolean(profileAnchorEl);

  useEffect(() => {
    if (!role || Object.keys(role).length <= 0) {
      return logOut && logOut();
    }
  }, [pathname, logOut, role, getMerchantInRedis]);
    

  const CustomMenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  
  const renderActiveClass = (path) => {
    if(search){
      if(pathname.concat(search) === path) return "Mui-selected";
    }else{
      return "Mui-selected"
    }
  }
    
  const SingleLevel = ({ item }) => {
    const hasPermission = verifyPermission(user, item);
    return (
      <>
      {item.to === "/loan-requests" && <Divider/>}
      {hasPermission && 
        <ListItemButton component={NavLink} to={item.to} activeClassName={renderActiveClass(item.to)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} primaryTypographyProps={{ fontSize: "13px"}}/>
      </ListItemButton>
    }
      </>
    );
  };

  const MultiLevel = ({ item }) => {
    const location = useLocation();
    const {pathname, search} = location;
    const { items: children } = item;
    const [open, setOpen] = React.useState(false);
    
    useEffect(()=>{
      
    const pathNames = item.items.map((i)=>i.to); 
      if(pathNames.includes(pathname.concat(search))){
        setOpen(true)
      }
    },[pathname, item, search])
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
  
    return (
      <React.Fragment>
        <ListItemButton onClick={handleClick} >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} primaryTypographyProps={{ fontSize: "13px" }}/>
          {/*{open ? <ExpandLess /> : <ExpandMore />}*/}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <CustomMenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };
  
  // handler
  const handleSettingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingAnchorEl(event.currentTarget);
  };
  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleSettingClose = (e, prop?:string) => {
    setSettingAnchorEl(null);
    if(prop){
      history.push(prop)
    }
  
  };
  const handleProfileClose = (e) => {
    setProfileAnchorEl(null);
  
  };

  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ color: "#111111", height: "100vh" }}>
      <Box sx={{ width: "200px", padding: "1rem" }}>
        {" "}
        <img src={HashirLogo} alt="logo" style={{ width: "200px", height:"80px" }} />
      </Box>
      <Divider />
      <StyledList>
      {
        menu.map((item, key) => <CustomMenuItem key={key} item={item} />)
      }
      </StyledList>
     
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#F1F1F1",
          boxShadow: "none",
          color: "#3C3C3C",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <IconButton
              id="setting-button"
              aria-controls={settingOpen ? "setting-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={settingOpen ? "true" : undefined}
              onClick={handleSettingClick}
            >
              <SettingsIcon sx={{ marginRight: "0.5rem" }} />
            </IconButton>
            <Menu
              id="setting-menu"
              anchorEl={settingAnchorEl}
              open={settingOpen}
              onClose={handleSettingClose}
              MenuListProps={{
                "aria-labelledby": "setting-button",
              }}
            >
              <MenuItem onClick={(e)=>handleSettingClose(e, "/users")}>
                <NavLink
                  to="/users"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Users
                </NavLink>
              </MenuItem>
              <MenuItem onClick={(e)=>handleSettingClose(e, "roles")}>
                <NavLink
                  to="/roles"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Roles
                </NavLink>
              </MenuItem>
              {userTypeId === "Walee" &&
              <MenuItem onClick={handleSettingClose}>
                <NavLink
                  to="/checkout-logs"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Checkout Logs
                </NavLink>
              </MenuItem>}

               {userTypeId === "Walee" && <MenuItem onClick={handleSettingClose}>
                <NavLink
                  to="/checkout-requests"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Checkout Requests
                </NavLink>
              </MenuItem>
              }
            </Menu>

            {/* Profile Dropdown Menu */}
            <IconButton
              id="profile-button"
              aria-controls={profileOpen ? "profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={profileOpen ? "true" : undefined}
              onClick={handleProfileClick}
            >
              <PersonOutlineOutlinedIcon />
            </IconButton>
          </Box>

          <Menu
            id="profile-menu"
            anchorEl={profileAnchorEl}
            open={profileOpen}
            onClose={handleProfileClose}
            MenuListProps={{
              "aria-labelledby": "profile-button",
            }}
          >
            <MenuItem onClick={handleProfileClose}>
              <NavLink
                to="/account-profile"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Account Profile
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleProfileClose}>
              <NavLink
                to="/change-password"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Change Password
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to="/logout"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Sign Out
              </NavLink>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          backgroundColor: "#F1F1F1",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}


