import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { LoanAccountBalance } from "./loanAccountBalance.type";
const url = process.env.REACT_APP_CREDID_LOAN_URL;

const initialState: LoanAccountBalance = {
  data: [],
};

const getLoanAccounts = createAsyncThunk(
  "loanAccountBalance/getLoanAccountsStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-accounts/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(url!.concat(pathname), { headers })
      );
      
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getLoanAccount = createAsyncThunk(
  "loanAccountBalance/getLoanAccountsStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(url!.concat(pathname), { headers })
      );
      console.log("Success response from loan requests :", response.data);
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const loanAccountBalanceSlice = createSlice({
  name: "loanAccountBalance",

  initialState: initialState,

  reducers: {
    setLoanAccountBalance: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLoanAccounts.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const loanAccountBalanceReducer = loanAccountBalanceSlice.reducer;

export const loanAccountBalanceActions = {
  ...loanAccountBalanceSlice.actions,
  getLoanAccounts,
  getLoanAccount,
};
