import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { LoanAccountBalanceDetail } from "./loanAccountBalanceDetail.type";
const url = process.env.REACT_APP_CREDID_LOAN_URL;
const initialState: LoanAccountBalanceDetail = {
  data: [],
};

const getLoanAccountDetails = createAsyncThunk(
  "loanAccountBalance/getLoanAccountDetailsStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/get/all/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(url!.concat(pathname), { headers })
      );
      console.log("Success response from loan account detail :", response.data);
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getLoanAccountDetail = createAsyncThunk(
  "loanAccountBalance/getLoanAccountDetailStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(url!.concat(pathname), { headers })
      );
      console.log("Success response from loan account detail :", response.data);
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addLoanAccountDetail = createAsyncThunk(
  "loanAccountBalance/addLoanAccountDetailStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log(
        "Success response from adding loan account detail :",
        response.data
      );

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editLoanAccountDetail = createAsyncThunk(
  "loanAccountBalance/editLoanAccountDetailStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(url!.concat(pathname), body, { headers })
      );
      console.log(
        "Success response from editing loan account detail :",
        response.data
      );

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteLoanAccountDetail = createAsyncThunk(
  "loanAccountBalance/deleteLoanAccountDetailStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-account-detail/delete/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(url!.concat(pathname), { headers })
      );
      console.log(
        "Success response from deleting loan account detail :",
        response.data
      );

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const loanAccountBalanceDetailSlice = createSlice({
  name: "loanAccountBalanceDetail",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setLoanAccountBalanceDetail: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLoanAccountDetail: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

  extraReducers: (builder) => {
    builder.addCase(getLoanAccountDetails.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(deleteLoanAccountDetail.fulfilled, (state, action) => {
      const deletedId = action.payload._id;
      state.data = state.data.filter((d) => d._id !== deletedId);
    });
  },
});

export const loanAccountBalanceDetailReducer =
  loanAccountBalanceDetailSlice.reducer;

export const loanAccountBalanceDetailActions = {
  ...loanAccountBalanceDetailSlice.actions,
  getLoanAccountDetails,
  getLoanAccountDetail,
  addLoanAccountDetail,
  editLoanAccountDetail,
  deleteLoanAccountDetail,
};
